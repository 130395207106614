import React, { useState } from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { toggleState } from "./contact";
import Img from "gatsby-image";
import "./header.scss";

export let toggleHeader: () => void;

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  toggleHeader = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          showelliLogo: file(relativePath: { eq: "showelli-logo.png" }) {
            childImageSharp {
              fixed(width: 94) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <header>
          <nav className="navbar nav is-white">
            <div className="container">
              <div className="navbar-start">
                <div className="navbar-brand">
                  <Link to="/" className="navbar-item">
                    <Img
                      fixed={data.showelliLogo.childImageSharp.fixed}
                      alt="Showelli logo"
                    />
                  </Link>
                  <a
                    onClick={event => {
                      event.preventDefault();
                      toggleHeader();
                    }}
                    role="button"
                    className="navbar-burger burger"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                  >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
                </div>
              </div>
              <div
                id={`overlay`}
                className={`${isOpen ? "is-active" : ""}`}
                onClick={() => {
                  toggleHeader();
                }}
              >
                <div className={`navbar-end ${isOpen ? " is-active" : ""}`}>
                  <Link to="/#about-us" className="navbar-item">
                    About Us
                  </Link>
                  <Link to="/gallery" className="navbar-item">
                    Gallery
                  </Link>
                  <Link to="/artists" className="navbar-item">
                    Artists
                  </Link>
                  <Link to="/acts" className="navbar-item">
                    Acts
                  </Link>
                  <div className="navbar-item">
                    <a
                      href="/"
                      onClick={event => {
                        event.preventDefault();
                        toggleState();
                      }}
                      className="button is-primary"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      )}
    />
  );
};
