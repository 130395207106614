import React from "react";
import { Link } from "gatsby";
import { toggleState } from "./contact";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        showelliLogo: file(relativePath: { eq: "showelli-logo.png" }) {
          childImageSharp {
            fixed(width: 94) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer className="footer">
          <div className="container">
            <div className="social-icons"></div>
            <Link to="/#about-us">About Us</Link> /{" "}
            <Link to="/gallery">Gallery</Link> /{" "}
            <Link to="/artists">Artists</Link> / <Link to="/acts">Acts</Link> /{" "}
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                toggleState();
              }}
            >
              Contact Us
            </a>{" "}
            /{" "}
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                toggleState();
              }}
            >
              Book Now
            </a>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="level">
              <div className="level-left">
                <Link to="/" className="navbar-item">
                  <Img
                    fixed={data.showelliLogo.childImageSharp.fixed}
                    alt="Showelli logo"
                  />
                </Link>
              </div>
              <div className="level-right">
                <ul>
                  <li>
                    <a href="mailto:showelli.entertainment@gmail.com">
                      showelli.entertainment@gmail.com
                    </a>
                  </li>
                  <li>
                    <a className="is-phone-link" href="tel:07528949979">
                      07528 949 979
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  />
);
